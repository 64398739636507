import React, { useState, useEffect } from "react";
import AdminLayout from "../../../Layout/AdminLayout";
import HealthCarePractitioner from "./MedRepMain";
import { useGetHCPQuery } from "../../../store/Schedules/SchedulesApi";
import MedRepComp from "./MedRep";
import {
  useGetMedRepQuery,
  useLazyGetNewMedRepQuery,
} from "../../../store/MedRep/MedRepApi";
import OffCanvasComp from "../../../Components/Ui/OffCanvas";
import AddFieldRep from "./submodules/addFieldRep";
import { useSelector } from "react-redux";
import { selectAllCurrentUserData } from "../../../store/auth";
import { storage } from "../../../utils/helper";

function MedRepMain() {
  const companyIde =
    storage["localStorage"].get("userProfile_pharmaServe")?.company?._id 
  console.log(companyIde)
  const { data: HCPData, isLoading } = useGetMedRepQuery(companyIde, {
    skip: !companyIde,
  });

  const [trigger, { data: HCPData2, isLoading: loading }] =
    useLazyGetNewMedRepQuery();

  const [showCavans, setShowCavans] = useState(false);

  useEffect(() => {
    console.log(companyIde);
    trigger({ companyId: companyIde, page: 1, search: "" });
  }, [companyIde]);

  return (
    <div>
      {HCPData2?.data?.medreps && (
        <MedRepComp
          HCP={HCPData2?.data?.medreps}
          hcpLoader={isLoading}
          totalData={HCPData2?.data?.total}
          pagination={HCPData2?.data?.pagination}
          setPage={trigger}
          companyId={companyIde}
        />
      )}
      <OffCanvasComp
        title={"Field Reps"}
        show={showCavans}
        bodyComponent={<AddFieldRep onClose={() => setShowCavans(false)} />}
        handleClose={() => {
          setShowCavans(false);
          // navigate(`/order_management`);
        }}
      />
    </div>
  );
}

export default MedRepMain;
