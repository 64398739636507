import ButtonComp from "../../Components/Ui/Button";
import { LanguageName } from "../../utils/helper";
import { SuccessIcon } from "../../utils/svg";

export default function SuccessAmrModal({ setSuccessModal }) {
  return (
    <div className="container">
      <div className="text-center py-10">
        <div className="flex justify-center mb-5">
          <SuccessIcon />
        </div>
        <h1 className="text-3xl font-bold mb-10">{LanguageName("FolderAssSucc")}</h1>
        <div className="flex justify-center gap-3">
          <ButtonComp
            text={LanguageName("CNC")}
            btnClassName={"bg-[#FFEBEB] w-32 mt-3 rounded text-[#8F0808] h-10"}
            onClick={() => setSuccessModal(false)}
          />{" "}
          <ButtonComp
            text={LanguageName("DN")}
            btnClassName={"bg-[#A162F7] w-32 mt-3 mr-5 rounded text-white h-10"}
            onClick={() => setSuccessModal(false)}
          />{" "}
        </div>
      </div>
    </div>
  );
}
