import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useGetUserProfileQuery } from "../store/User/userApi";
import {
  isAuth,
  logout,
  selectAllCurrentUserData,
  selectCurrentUserData,
} from "../store/auth";
import { storage } from "../utils/helper";

const withAuth = (WrappedComponent, redirectPath = "/login") => {
  const AuthComponent = (props) => {
    const dispatch = useDispatch();
    const isAuthState = useSelector(isAuth);
    const { data: userProfile, isSuccess, refetch } = useGetUserProfileQuery();
    const user = userProfile?.data;
    const getUser = useSelector(selectCurrentUserData);

    const isTokenExpired = (token) => {
      if (!token) return true;
      try {
        const payload = JSON.parse(atob(token.split(".")[1]));
        const currentTime = Math.floor(Date.now() / 1000);
        return payload.exp < currentTime;
      } catch (error) {
        console.error("Invalid token format:", error);
        return true;
      }
    };

    useEffect(() => {
      if (isAuthState) {
        refetch();
      }
    }, [isAuthState, refetch]);

    useEffect(() => {
      if (isSuccess) {
        storage.localStorage.set("userProfile_pharmaServe", userProfile.data);
      }
    }, [isSuccess, userProfile]);

    useEffect(() => {
      const token = storage.localStorage.get("user_details_profile")?.token;
      if (!getUser?.id || isTokenExpired(token)) {
        dispatch(logout());
      }
    }, [getUser?.id, dispatch]);

    const token = storage.localStorage.get("user_details_profile")?.token;
    if (!token || isTokenExpired(token)) {
      return <Navigate to={redirectPath} />;
    }

    return <WrappedComponent user={user} {...props} />;
  };

  return AuthComponent;
};

export default withAuth;

