import React from "react";
import InputComp from "../../Components/Ui/InputComp";
import ButtonComp from "../../Components/Ui/Button";
import { useVerifyCodeMutation } from "../../store/auth/authApi";
import { useNavigate } from "react-router-dom";
import useFormValidator from "use-form-input-validator";
import { toast } from "react-toastify";
import { LanguageName, storage } from "../../utils/helper";

const VerifyCode = () => {
  const { values, errors, updateField, isAllFieldsValid } = useFormValidator({
    code: { checks: "required", value: "" },
  });
  console.log(values)
  const navigate = useNavigate()
  const [verifyCode, { isLoading, isSuccess, data }] = useVerifyCodeMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const email =  storage["localStorage"].get("verified_mail")
    if (isAllFieldsValid()) {
      const loginDetails = await verifyCode({...values, email});
      const { data, error } = loginDetails;
      if (error) {
        return toast.error(error?.data?.message);
      }
      toast.success("Verification successful")
      storage["localStorage"].set("verification", data)
      storage["localStorage"].remove("verified_mail")
      navigate("/reset-password")
    }
  };
  return (
    <div className=" ">
      <div className="flex justify-start items-center mb-32">
        <div className="px-[50px] lg:px-[80px] lg:pl-[72px] flex-1">
          <div className="mb-5 text-center">
            <h3 className="text-[32px] font-semibold mb-[0px]">{LanguageName("verifyCode")}</h3>
            <p className="text-[#333333]">
              {LanguageName("KindlyEnterYourCode")}
            </p>
          </div>
          <form className="w-full lg:pr-[119px] row gy-4 " autoComplete="off">
            <InputComp
              label={"Code"}
              name="code"
              onChange={updateField}
              value={values.code}
              error={errors.code}
              placeholder={LanguageName("enterCode")}
              containerClassName={
                "border-[#918EF4] border-x-0 border-t-0 rounded-0  !border-b-[1px]"
              }
            />

            {/* <TextInputComp label={""} /> */}
            <ButtonComp
                onClick={handleSubmit}
                loader={isLoading}
              text={LanguageName("proceed")}
              btnClassName={
                "bg-[#8652CE] shadow-lg text-white py-[10px] rounded-full w-full text-center font-medium "
              }
            />

            <div className="text-[#282728] text-center flex justify-center items-center gap-2 text-[14px]">
              {LanguageName("contactWhatsapp")}
              <span
                onClick={() => redirectToWhatsApp()}
                className="text-[#4F008A] hover:underline cursor-pointer flex items-center gap-1"
              >
                {" "}
                <span className="text-[#25D366] cursor-pointer">Whatsapp</span>
                <img src="/icon/whatsapp.svg" />
              </span>{" "}
            </div>
          </form>
        </div>
        {/*  */}
      </div>
      <div className="flex justify-between items-center w-[560px] container text-[14px] text-[#1A002E]">
        <div>© PharmaServ 2023</div>
        <div>{LanguageName("privacyCondition")}</div>
      </div>
    </div>
  );
};

export default VerifyCode;
