import React, { useState, useEffect } from "react";
import ButtonComp from "../../Components/Ui/Button";
import {
  useGetAllMedRepsQuery,
  useGetAssignedMedRepQuery,
} from "../../store/Media/MediaApi";
import { useLazyGetNewMedRepQuery } from "../../store/MedRep/MedRepApi";
import { toast } from "react-toastify";
import { LanguageName, isObjectEmpty, storage } from "../../utils/helper";
import InputComp from "../../Components/Ui/InputComp";
import CheckboxComp from "../../Components/Ui/Checkbox";
import Skeleton from "react-loading-skeleton";
import { Pagination } from "../../Components/Common/Pagination";

export default function AssignMRModal({
  setShowAMRModal,
  folderId,
  setAssuranceModal,
  setMedRep,
  AMRSuccess,
  Data,
  isAssigned,
  setIsAssigned,
  loader,
  AMRFetcher,
  setUpdateModal,
}) {
  const companyId = storage["localStorage"].get("userProfile_pharmaServe")
    ?.company?._id;
  const [triggerMedRep, { data: MedRepData, isLoading: MRloader, isFetching }] =
    useLazyGetNewMedRepQuery();
  const { data: assignedMedRepData, isSuccess } =
    useGetAssignedMedRepQuery(folderId);

  const [filteredItems, setFilteredItems] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [medreps, setMedreps] = useState([]);
  const [editFolderId, setEditFolderId] = useState(null);

  function searchItems(items, searchTerm) {
    if (!items) return [];
    return items?.filter((item) => {
      const searchLower = searchTerm.toLowerCase();
      const firstNameMatch = item.firstName.toLowerCase().includes(searchLower);
      const lastNameMatch = item.lastName.toLowerCase().includes(searchLower);
      return firstNameMatch || lastNameMatch;
    });
  }

  const handleSearch = (event) => {
    triggerMedRep({ companyId, page: 1, search: event.target.value });
  };

  const handleSave = () => {
    let payload;
    if (isAssigned) {
      payload = {
        medRepId: medreps,
        folderId: Data?.data[0]?._id,
        id: folderId,
      };
    } else {
      payload = {
        companyId,
        medRepId: medreps,
        folderId,
      };
    }
    setMedRep(payload);
  };

  const addMedRep = (id) => {
    setMedreps((prev) => {
      if (prev.includes(id)) {
        // If the checkbox is unchecked, call the function here
        handleUnchecked(id);
        return prev.filter((item) => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };
  const handleUnchecked = (id) => {
    console.log(`Checkbox with id ${id} was unchecked`);
    setMedreps((prev) => prev.filter((item) => item !== id));
  };

  useEffect(() => {
    if (AMRSuccess) {
      if (!Data?.status) return;
      const newData = Data?.data[0]?.medRep.map((item) => item._id);
      setMedreps(Array.from(new Set(newData)));
      if (Array.from(new Set(newData)).length > 0) {
        setIsAssigned(true);
      }
    }
    return () => {
      setMedreps([]);
      setIsAssigned(false);
    };
  }, [AMRSuccess, Data]);

  useEffect(() => {
    triggerMedRep({ companyId, page: 1, search: "" });
  }, []);

  return (
    <div className="container">
      <div className="">
        <h2 className="font-medium mb-2 text-lg">{LanguageName("SMRA")}:</h2>
        <InputComp
          suffixIcon={
            <div className="ps-2 pe-3">
              <img className="" src="/icon/search.png" alt="search" />
            </div>
          }
          containerClassName={"border-2"}
          placeholder={LanguageName("SearchMR")}
          onChange={handleSearch}
        />
        <div className="border-2 rounded mt-3 h-96 overflow-auto">
          {(MRloader || isFetching) && (
            <>
              <Skeleton /> <Skeleton /> <Skeleton />
            </>
          )}

          {MedRepData &&
            Array.isArray(MedRepData.data?.medreps) &&
            !MRloader &&
            !isFetching && (
              <div>
                {MedRepData?.data?.medreps?.map((item) => (
                  <div key={item.medrepId}>
                    <CheckboxComp
                      className={
                        "flex justify-between flex-row-reverse px-4 mb-3 accent-[#50d71e]"
                      }
                      label={`${item.firstName}  ${item.lastName}`}
                      onChange={() => addMedRep(item.medrepId)}
                      checked={medreps.includes(item.medrepId)}
                      labelClassName={""}
                    />
                  </div>
                ))}
              </div>
            )}
        </div>
        <div className="flex justify-center items-center flex-col">
          {MedRepData && (
            <Pagination
              current={MedRepData?.data?.pagination?.current}
              numberOfPages={MedRepData?.data?.pagination?.number_of_pages}
              next={MedRepData?.data?.pagination?.next}
              setPage={triggerMedRep}
            />
          )}
          <ButtonComp
            text={LanguageName("Save")}
            btnClassName={
              "bg-[#3DA84A] w-32 mt-3 rounded-full text-white h-10 pb-2"
            }
            onClick={() => {
              handleSave();
              if (isAssigned) {
                setUpdateModal(true);
                setShowAMRModal(false);
              }
              setAssuranceModal(true);
              setShowAMRModal(false);
            }}
            // disabled={isObjectEmpty(medRep)}
          />{" "}
        </div>
      </div>
    </div>
  );
}
