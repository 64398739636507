
import ButtonComp from "../../Components/Ui/Button";
import { LanguageName } from "../../utils/helper";

export default function ConfirmAssignModal({ setAssuranceModal, save, loader }) {
  
  return (
    <div className="container">
      <h2 className="my-4 font-bold text-2xl">{LanguageName("AssMRep")}</h2>
      <p className="font-medium text-lg mt-10 mb-7">
        {LanguageName("AssMRepDes")}
      </p>
      <div className="flex justify-center gap-3">
        <ButtonComp
          text={LanguageName("CNC")}
          btnClassName={
            "border-2 border-[#D63434] w-32 mt-3 rounded text-[#D63434] h-10"
          }
          onClick={() => setAssuranceModal(false)}
        />{" "}
        <ButtonComp
          text={LanguageName("YIS")}
          btnClassName={
            "bg-[#3DA84A] w-32 mt-3 mr-5 rounded text-white h-10"
          }
          loader={loader}
          disabled={loader}
          onClick={save}
        />{" "}
      </div>
    </div>
  );
}
